import React from "react";
import Link from "next/link";
import { Box, Text } from "@chakra-ui/react";

export default function Footer() {
  return (
    <Box
      as="footer"
      mt="auto"
      bg="black"
      px={5}
      py={10}
      textAlign="center"
      fontSize={12}
      color="white"
    >
      <Link href="/privacy_policy/">
        <a>プライバシーポリシー</a>
      </Link>
      <Text as="span" mx={3}>
        |
      </Text>
      <Link href="/business/">
        <a>会社概要</a>
      </Link>
      <Text as="span" mx={3}>
        |
      </Text>
      <Text as="span" textAlign="center">
        © 2020 FASTLANE
      </Text>
    </Box>
  );
}
