import React, { ReactNode } from "react";
import Header from "@src/components/Header";
import Footer from "@src/components/Footer";
import { Box } from "@chakra-ui/react";

interface Props {
  children: ReactNode;
}

const CommonLayout = ({ children }: Props) => (
  <Box display="flex" flexDirection="column" minH="100vh">
    <Header />
    <Box flex={1}>{children}</Box>
    <Footer />
  </Box>
);

export default CommonLayout;
