import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Time: any;
  Upload: any;
};

export type Query = {
  node?: Maybe<Node>;
  nodes: Array<Maybe<Node>>;
  currentUser: User;
  userCreditCards: Array<CreditCard>;
  prefecture: Prefecture;
  smallArea: SmallArea;
  allShops: Array<Shop>;
  allShopIds: Array<Scalars["ID"]>;
  shop: Shop;
  shopInfo: Shop;
  shopInfoCandidates: Array<Maybe<Shop>>;
  allWaitingLineObservations: Array<WaitingLineObservation>;
  shopWaitingLineObservationsWithImg: Array<WaitingLineObservation>;
  userWaitingLineObservations: Array<WaitingLineObservation>;
  fastLane: FastLane;
  fastLaneUsageHistory: FastLaneUsageHistory;
  userFastLaneUsageHistories: Array<FastLaneUsageHistory>;
  kaijinCampfireFastLaneFreeTicket: Scalars["Boolean"];
  kaijinCampfireFastLanePassportTicket: Scalars["Boolean"];
  userActiveFastLanePassports: Array<UserFastLanePassport>;
  userAvailableFastLanePassportByFastLaneID: UserFastLanePassport;
  relatedPosts: Array<Post>;
  allPosts: PostsConnection;
  searchPosts: PostsConnection;
  shopPosts: PostsConnection;
  tagPosts: PostsConnection;
};

export type QueryNodeArgs = {
  id: Scalars["ID"];
};

export type QueryNodesArgs = {
  ids: Array<Scalars["ID"]>;
};

export type QueryPrefectureArgs = {
  id: Scalars["ID"];
};

export type QuerySmallAreaArgs = {
  id: Scalars["ID"];
};

export type QueryShopArgs = {
  id: Scalars["ID"];
};

export type QueryShopInfoArgs = {
  id: Scalars["ID"];
};

export type QueryShopInfoCandidatesArgs = {
  q: Scalars["String"];
};

export type QueryShopWaitingLineObservationsWithImgArgs = {
  shopId: Scalars["ID"];
};

export type QueryUserWaitingLineObservationsArgs = {
  userId: Scalars["ID"];
};

export type QueryFastLaneArgs = {
  shopId: Scalars["ID"];
};

export type QueryFastLaneUsageHistoryArgs = {
  id: Scalars["ID"];
};

export type QueryKaijinCampfireFastLaneFreeTicketArgs = {
  promoCode: Scalars["String"];
};

export type QueryKaijinCampfireFastLanePassportTicketArgs = {
  promoCode: Scalars["String"];
};

export type QueryUserAvailableFastLanePassportByFastLaneIdArgs = {
  fastLaneId: Scalars["ID"];
};

export type QueryRelatedPostsArgs = {
  id: Scalars["ID"];
};

export type QueryAllPostsArgs = {
  page: Scalars["Int"];
  pageSize: Scalars["Int"];
};

export type QuerySearchPostsArgs = {
  query: Scalars["String"];
  page: Scalars["Int"];
  pageSize: Scalars["Int"];
};

export type QueryShopPostsArgs = {
  shopId: Scalars["ID"];
  page: Scalars["Int"];
  pageSize: Scalars["Int"];
};

export type QueryTagPostsArgs = {
  tag: Scalars["String"];
  page: Scalars["Int"];
  pageSize: Scalars["Int"];
};

export type Mutation = {
  createShop: Shop;
  updateShop: Shop;
  updateShopScreenImg: Scalars["String"];
  uploadWaitingLineObservationListCsv: Scalars["Int"];
  registerCreditCard: CreditCard;
  deleteCreditCard: Scalars["Boolean"];
  createWaitingLineObservation: Scalars["Boolean"];
  payFastLane: FastLaneUsageHistory;
  useKaijinCampfireFastLaneFreeTicket: Scalars["Boolean"];
  useKaijinCampfireFastLanePassportTicket: Scalars["Boolean"];
  useFastLanePassport: FastLaneUsageHistory;
  enterShop: Scalars["Boolean"];
};

export type MutationCreateShopArgs = {
  input: CreateShopInput;
};

export type MutationUpdateShopArgs = {
  input: UpdateShopInput;
};

export type MutationUpdateShopScreenImgArgs = {
  input: UpdateShopScreenImgInput;
};

export type MutationUploadWaitingLineObservationListCsvArgs = {
  input: Scalars["Upload"];
};

export type MutationRegisterCreditCardArgs = {
  cardToken: Scalars["String"];
};

export type MutationDeleteCreditCardArgs = {
  id: Scalars["ID"];
};

export type MutationCreateWaitingLineObservationArgs = {
  input?: Maybe<CreateWaitingLineObservationInput>;
};

export type MutationPayFastLaneArgs = {
  input: PayFastLaneInput;
};

export type MutationUseKaijinCampfireFastLaneFreeTicketArgs = {
  promoCode: Scalars["String"];
};

export type MutationUseKaijinCampfireFastLanePassportTicketArgs = {
  promoCode: Scalars["String"];
};

export type MutationUseFastLanePassportArgs = {
  fastLaneId: Scalars["ID"];
  userFastLanePassportId: Scalars["ID"];
};

export type MutationEnterShopArgs = {
  fastLaneUsageHistoryId: Scalars["ID"];
};

export enum Role {
  Admin = "ADMIN",
  User = "USER",
  PreUser = "PRE_USER",
}

export type Node = {
  id: Scalars["ID"];
};

export enum ErrorCode {
  Unauthenticated = "UNAUTHENTICATED",
  Unauthorized = "UNAUTHORIZED",
  AlreadyExist = "ALREADY_EXIST",
  InvalidArgument = "INVALID_ARGUMENT",
  Expired = "EXPIRED",
  NotFound = "NOT_FOUND",
  Internal = "INTERNAL",
  CardDeclined = "CARD_DECLINED",
  BalanceInsufficient = "BALANCE_INSUFFICIENT",
  IncorrectCvc = "INCORRECT_CVC",
  ExpiredCard = "EXPIRED_CARD",
  ChargeProcessingError = "CHARGE_PROCESSING_ERROR",
}

export type SimplePageInfo = {
  page: Scalars["Int"];
  totalPage: Scalars["Int"];
};

export type User = Node & {
  id: Scalars["ID"];
  userId?: Maybe<Scalars["String"]>;
  email: Scalars["String"];
  displayName: Scalars["String"];
  screenImgUrl?: Maybe<Scalars["String"]>;
};

export type UserPublic = Node & {
  id: Scalars["ID"];
  userId: Scalars["String"];
  displayName: Scalars["String"];
  screenImgUrl?: Maybe<Scalars["String"]>;
};

export type Prefecture = Node & {
  id: Scalars["ID"];
  regionId: Scalars["ID"];
  name: Scalars["String"];
  shortName: Scalars["String"];
  enName: Scalars["String"];
  kanaName: Scalars["String"];
  middleAreas: Array<MiddleArea>;
  stations: Array<Station>;
};

export type MiddleArea = Node & {
  id: Scalars["ID"];
  name: Scalars["String"];
  prefecture: Prefecture;
  smallAreas: Array<SmallArea>;
};

export type SmallArea = Node & {
  id: Scalars["ID"];
  name: Scalars["String"];
  middleArea: MiddleArea;
  shops: Array<Shop>;
};

export type Station = Node & {
  id: Scalars["ID"];
  name: Scalars["String"];
  railwayLineName: Scalars["String"];
  postalCode: Scalars["String"];
  address: Scalars["String"];
  lat: Scalars["Float"];
  lng: Scalars["Float"];
  operationStatus: Scalars["Int"];
  sortNum: Scalars["Int"];
  shops: Array<Shop>;
};

export type Shop = Node & {
  id: Scalars["ID"];
  name: Scalars["String"];
  screenImgUrl: Scalars["String"];
  smallArea: SmallArea;
  station: Station;
  lunchHourlyWaitingTimes: Array<HourlyWaitingTime>;
  lat: Scalars["Float"];
  lng: Scalars["Float"];
  postalCode: Scalars["String"];
  address: Scalars["String"];
  access: Scalars["String"];
  phoneNumber?: Maybe<Scalars["String"]>;
  closeInfo?: Maybe<Scalars["String"]>;
  tabelogRating?: Maybe<Scalars["Float"]>;
  tabelogUrl?: Maybe<Scalars["String"]>;
  instagramHashtag?: Maybe<Scalars["String"]>;
  lunchAveragePriceMin: Scalars["Int"];
  lunchAveragePriceMax: Scalars["Int"];
  dinnerAveragePriceMin: Scalars["Int"];
  dinnerAveragePriceMax: Scalars["Int"];
};

export type WaitingLineObservation = {
  id: Scalars["ID"];
  shopId: Scalars["ID"];
  shop: Shop;
  waitingTime?: Maybe<Scalars["Int"]>;
  waitingPeopleNum: Scalars["Int"];
  observedBy: Scalars["String"];
  observedAt: Scalars["Time"];
  imgUrl?: Maybe<Scalars["String"]>;
  createdAt: Scalars["Time"];
};

export type HourlyWaitingTime = {
  shopId: Scalars["ID"];
  hour: Scalars["Int"];
  averageWaitingTime: Scalars["Int"];
  averageWaitingPeopleNum: Scalars["Int"];
};

export type Post = Node & {
  id: Scalars["ID"];
  url: Scalars["String"];
  title: Scalars["String"];
  description: Scalars["String"];
  screenImgUrl: Scalars["String"];
};

export type PostsConnection = {
  pageInfo: SimplePageInfo;
  nodes: Array<Post>;
};

export enum FastLaneStatus {
  Active = "ACTIVE",
  Inactive = "INACTIVE",
  Closed = "CLOSED",
}

export type FastLane = {
  id: Scalars["ID"];
  shop: Shop;
  status: FastLaneStatus;
  defaultPrice: Scalars["Int"];
  maxUserNum: Scalars["Int"];
  currentPrice?: Maybe<FastLaneCurrentPrice>;
  createdAt: Scalars["Time"];
  updatedAt: Scalars["Time"];
};

export type FastLaneCurrentPrice = {
  price: Scalars["Int"];
  priceHash: Scalars["String"];
  calculatedAt: Scalars["Time"];
  calculatedAtHash: Scalars["String"];
  usedCountInLast30Minutes: Scalars["Int"];
};

export type FastLaneUsageHistory = {
  id: Scalars["ID"];
  fastLane: FastLane;
  charge?: Maybe<Charge>;
  price: Scalars["Int"];
  userNum: Scalars["Int"];
  isEntered: Scalars["Boolean"];
  createdAt: Scalars["Time"];
};

export type Charge = {
  id: Scalars["ID"];
  amount: Scalars["Int"];
  receiptUrl: Scalars["String"];
};

export enum FastLanePassportTargetType {
  All = "ALL",
  Targeted = "TARGETED",
}

export type FastLanePassport = {
  id: Scalars["ID"];
  name: Scalars["String"];
  targetType: FastLanePassportTargetType;
  fastLanes: Array<FastLane>;
  createdAt: Scalars["Time"];
};

export type UserFastLanePassport = {
  id: Scalars["ID"];
  user: User;
  fastLanePassport: FastLanePassport;
  startsAt: Scalars["Time"];
  endsAt: Scalars["Time"];
  createdAt: Scalars["Time"];
};

export enum CardBrand {
  AmericanExpress = "AMERICAN_EXPRESS",
  Discover = "DISCOVER",
  DinersClub = "DINERS_CLUB",
  Jcb = "JCB",
  MasterCard = "MASTER_CARD",
  UnionPay = "UNION_PAY",
  Visa = "VISA",
  Unknown = "UNKNOWN",
}

export type CreditCard = {
  id: Scalars["ID"];
  user: User;
  brand: CardBrand;
  last4: Scalars["String"];
  expYear: Scalars["Int"];
  expMonth: Scalars["Int"];
  createdAt: Scalars["Time"];
};

export type CreateUserInput = {
  userId: Scalars["String"];
  email: Scalars["String"];
  displayName: Scalars["String"];
};

export type CreateWaitingLineObservationInput = {
  shopId: Scalars["ID"];
  waitingPeopleNum: Scalars["Int"];
  waitingTime?: Maybe<Scalars["Int"]>;
  observedAt: Scalars["Time"];
  img?: Maybe<Scalars["Upload"]>;
};

export type CreateShopInput = {
  smallAreaId: Scalars["ID"];
  stationId: Scalars["ID"];
  name: Scalars["String"];
  lat: Scalars["Float"];
  lng: Scalars["Float"];
  postalCode: Scalars["String"];
  address: Scalars["String"];
  access: Scalars["String"];
  phoneNumber?: Maybe<Scalars["String"]>;
  closeInfo?: Maybe<Scalars["String"]>;
  tabelogRating?: Maybe<Scalars["Float"]>;
  tabelogUrl?: Maybe<Scalars["String"]>;
  instagramHashtag?: Maybe<Scalars["String"]>;
  lunchAveragePriceMin: Scalars["Int"];
  lunchAveragePriceMax: Scalars["Int"];
  dinnerAveragePriceMin: Scalars["Int"];
  dinnerAveragePriceMax: Scalars["Int"];
};

export type UpdateShopInput = {
  id: Scalars["ID"];
  smallAreaId: Scalars["ID"];
  stationId: Scalars["ID"];
  name: Scalars["String"];
  lat: Scalars["Float"];
  lng: Scalars["Float"];
  postalCode: Scalars["String"];
  address: Scalars["String"];
  access: Scalars["String"];
  phoneNumber?: Maybe<Scalars["String"]>;
  closeInfo?: Maybe<Scalars["String"]>;
  tabelogRating?: Maybe<Scalars["Float"]>;
  tabelogUrl?: Maybe<Scalars["String"]>;
  instagramHashtag?: Maybe<Scalars["String"]>;
  lunchAveragePriceMin: Scalars["Int"];
  lunchAveragePriceMax: Scalars["Int"];
  dinnerAveragePriceMin: Scalars["Int"];
  dinnerAveragePriceMax: Scalars["Int"];
};

export type UpdateShopScreenImgInput = {
  shopId: Scalars["ID"];
  img: Scalars["Upload"];
};

export type PayFastLaneInput = {
  fastLaneId: Scalars["String"];
  email: Scalars["String"];
  userNum: Scalars["Int"];
  price: Scalars["Int"];
  priceHash: Scalars["String"];
  priceCalculatedAt: Scalars["Time"];
  priceCalculatedAtHash: Scalars["String"];
  chargeToken?: Maybe<Scalars["String"]>;
  creditCardId?: Maybe<Scalars["ID"]>;
  idempotencyKey: Scalars["String"];
};

export type CreateWaitingLineObservationMutationVariables = Exact<{
  input?: Maybe<CreateWaitingLineObservationInput>;
}>;

export type CreateWaitingLineObservationMutation = Pick<
  Mutation,
  "createWaitingLineObservation"
>;

export type CreateShopMutationVariables = Exact<{
  input: CreateShopInput;
}>;

export type CreateShopMutation = { createShop: Pick<Shop, "id" | "name"> };

export type UpdateShopMutationVariables = Exact<{
  input: UpdateShopInput;
}>;

export type UpdateShopMutation = { updateShop: Pick<Shop, "id" | "name"> };

export type UpdateShopScreenImgMutationVariables = Exact<{
  input: UpdateShopScreenImgInput;
}>;

export type UpdateShopScreenImgMutation = Pick<Mutation, "updateShopScreenImg">;

export type UploadWaitingLineObservationListCsvMutationVariables = Exact<{
  file: Scalars["Upload"];
}>;

export type UploadWaitingLineObservationListCsvMutation = Pick<
  Mutation,
  "uploadWaitingLineObservationListCsv"
>;

export type RegisterCreditCardMutationVariables = Exact<{
  cardToken: Scalars["String"];
}>;

export type RegisterCreditCardMutation = {
  registerCreditCard: Pick<
    CreditCard,
    "id" | "brand" | "last4" | "expYear" | "expMonth" | "createdAt"
  >;
};

export type DeleteCreditCardMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type DeleteCreditCardMutation = Pick<Mutation, "deleteCreditCard">;

export type PayFastLaneMutationVariables = Exact<{
  input: PayFastLaneInput;
}>;

export type PayFastLaneMutation = {
  payFastLane: Pick<
    FastLaneUsageHistory,
    "id" | "price" | "userNum" | "isEntered" | "createdAt"
  > & { fastLane: { shop: Pick<Shop, "name" | "screenImgUrl"> } };
};

export type UseKaijinCampfireFastLaneFreeTicketMutationVariables = Exact<{
  promoCode: Scalars["String"];
}>;

export type UseKaijinCampfireFastLaneFreeTicketMutation = Pick<
  Mutation,
  "useKaijinCampfireFastLaneFreeTicket"
>;

export type UseKaijinCampfireFastLanePassportTicketMutationVariables = Exact<{
  promoCode: Scalars["String"];
}>;

export type UseKaijinCampfireFastLanePassportTicketMutation = Pick<
  Mutation,
  "useKaijinCampfireFastLanePassportTicket"
>;

export type UseFastLanePassportMutationVariables = Exact<{
  fastLaneId: Scalars["ID"];
  userFastLanePassportId: Scalars["ID"];
}>;

export type UseFastLanePassportMutation = {
  useFastLanePassport: Pick<FastLaneUsageHistory, "id">;
};

export type EnterShopMutationVariables = Exact<{
  fastLaneUsageHistoryId: Scalars["ID"];
}>;

export type EnterShopMutation = Pick<Mutation, "enterShop">;

export type SimplePageInfoFragment = Pick<SimplePageInfo, "page" | "totalPage">;

export type UserInfoFragment = Pick<
  User,
  "id" | "userId" | "email" | "displayName" | "screenImgUrl"
>;

export type UserPublicInfoFragment = Pick<
  User,
  "id" | "userId" | "displayName" | "screenImgUrl"
>;

export type ShopInfoFragment = Pick<
  Shop,
  | "id"
  | "name"
  | "screenImgUrl"
  | "lat"
  | "lng"
  | "postalCode"
  | "address"
  | "access"
  | "phoneNumber"
  | "closeInfo"
  | "tabelogRating"
  | "tabelogUrl"
  | "instagramHashtag"
  | "lunchAveragePriceMin"
  | "lunchAveragePriceMax"
  | "dinnerAveragePriceMin"
  | "dinnerAveragePriceMax"
>;

export type LunchHourlyWaitingTimeInfoFragment = Pick<
  HourlyWaitingTime,
  "shopId" | "hour" | "averageWaitingPeopleNum" | "averageWaitingTime"
>;

export type PostInfoFragment = Pick<
  Post,
  "id" | "url" | "title" | "description" | "screenImgUrl"
>;

export type CurrentUserQueryVariables = Exact<{ [key: string]: never }>;

export type CurrentUserQuery = { currentUser: UserInfoFragment };

export type UserCreditCardsQueryVariables = Exact<{ [key: string]: never }>;

export type UserCreditCardsQuery = {
  userCreditCards: Array<
    Pick<
      CreditCard,
      "id" | "brand" | "last4" | "expYear" | "expMonth" | "createdAt"
    >
  >;
};

export type AllShopsQueryVariables = Exact<{ [key: string]: never }>;

export type AllShopsQuery = {
  allShops: Array<
    Pick<Shop, "id" | "name"> & {
      smallArea: Pick<SmallArea, "id" | "name"> & {
        middleArea: Pick<MiddleArea, "id">;
      };
    }
  >;
};

export type AllShopIdsQueryVariables = Exact<{ [key: string]: never }>;

export type AllShopIdsQuery = Pick<Query, "allShopIds">;

export type PrefectureQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type PrefectureQuery = {
  prefecture: Pick<Prefecture, "name"> & {
    middleAreas: Array<
      Pick<MiddleArea, "id" | "name"> & {
        smallAreas: Array<Pick<SmallArea, "id" | "name">>;
      }
    >;
    stations: Array<
      Pick<
        Station,
        "id" | "name" | "railwayLineName" | "lat" | "lng" | "sortNum"
      >
    >;
  };
};

export type SmallAreaQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type SmallAreaQuery = {
  smallArea: Pick<SmallArea, "name"> & {
    shops: Array<
      {
        lunchHourlyWaitingTimes: Array<LunchHourlyWaitingTimeInfoFragment>;
        station: Pick<Station, "name" | "lat" | "lng">;
      } & ShopInfoFragment
    >;
  };
};

export type ShopQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type ShopQuery = {
  shop: {
    smallArea: Pick<SmallArea, "id" | "name"> & {
      middleArea: Pick<MiddleArea, "id"> & {
        prefecture: Pick<Prefecture, "id">;
      };
    };
    station: Pick<Station, "id" | "name" | "railwayLineName">;
    lunchHourlyWaitingTimes: Array<LunchHourlyWaitingTimeInfoFragment>;
  } & ShopInfoFragment;
};

export type ShopInfoCandidatesQueryVariables = Exact<{
  q: Scalars["String"];
}>;

export type ShopInfoCandidatesQuery = {
  shopInfoCandidates: Array<Maybe<ShopInfoFragment>>;
};

export type ShopInfoQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type ShopInfoQuery = { shopInfo: ShopInfoFragment };

export type AllWaitingLineObservationsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type AllWaitingLineObservationsQuery = {
  allWaitingLineObservations: Array<
    Pick<
      WaitingLineObservation,
      | "id"
      | "shopId"
      | "waitingTime"
      | "waitingPeopleNum"
      | "observedBy"
      | "observedAt"
      | "createdAt"
    >
  >;
};

export type ShopWaitingLineObservationsWithImgQueryVariables = Exact<{
  shopId: Scalars["ID"];
}>;

export type ShopWaitingLineObservationsWithImgQuery = {
  shopWaitingLineObservationsWithImg: Array<
    Pick<WaitingLineObservation, "imgUrl">
  >;
};

export type UserWaitingLineObservationsQueryVariables = Exact<{
  userId: Scalars["ID"];
}>;

export type UserWaitingLineObservationsQuery = {
  userWaitingLineObservations: Array<
    Pick<
      WaitingLineObservation,
      "id" | "waitingTime" | "waitingPeopleNum" | "observedAt" | "imgUrl"
    > & { shop: Pick<Shop, "id" | "name" | "screenImgUrl"> }
  >;
};

export type FastLaneQueryVariables = Exact<{
  shopId: Scalars["ID"];
}>;

export type FastLaneQuery = {
  fastLane: Pick<
    FastLane,
    "id" | "status" | "defaultPrice" | "maxUserNum" | "createdAt" | "updatedAt"
  > & {
    currentPrice?: Maybe<
      Pick<
        FastLaneCurrentPrice,
        | "price"
        | "priceHash"
        | "calculatedAt"
        | "calculatedAtHash"
        | "usedCountInLast30Minutes"
      >
    >;
    shop: ShopInfoFragment;
  };
};

export type FastLaneUsageHistoryQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type FastLaneUsageHistoryQuery = {
  fastLaneUsageHistory: Pick<
    FastLaneUsageHistory,
    "id" | "price" | "userNum" | "isEntered" | "createdAt"
  > & {
    fastLane: { shop: Pick<Shop, "id" | "name" | "screenImgUrl"> };
    charge?: Maybe<Pick<Charge, "amount" | "receiptUrl">>;
  };
};

export type UserFastLaneUsageHistoriesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type UserFastLaneUsageHistoriesQuery = {
  userFastLaneUsageHistories: Array<
    Pick<FastLaneUsageHistory, "id" | "isEntered" | "createdAt"> & {
      fastLane: { shop: Pick<Shop, "name" | "screenImgUrl"> };
    }
  >;
};

export type UserActiveFastLanePassportsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type UserActiveFastLanePassportsQuery = {
  userActiveFastLanePassports: Array<
    Pick<UserFastLanePassport, "id" | "startsAt" | "endsAt"> & {
      fastLanePassport: Pick<FastLanePassport, "name" | "targetType"> & {
        fastLanes: Array<{ shop: Pick<Shop, "id" | "name" | "screenImgUrl"> }>;
      };
    }
  >;
};

export type UserAvailableFastLanePassportByFastLaneIdQueryVariables = Exact<{
  fastLaneId: Scalars["ID"];
}>;

export type UserAvailableFastLanePassportByFastLaneIdQuery = {
  userAvailableFastLanePassportByFastLaneID: Pick<
    UserFastLanePassport,
    "id"
  > & { fastLanePassport: Pick<FastLanePassport, "name"> };
};

export type KaijinCampfireFastLaneFreeTicketQueryVariables = Exact<{
  promoCode: Scalars["String"];
}>;

export type KaijinCampfireFastLaneFreeTicketQuery = Pick<
  Query,
  "kaijinCampfireFastLaneFreeTicket"
>;

export type KaijinCampfireFastLanePassportTicketQueryVariables = Exact<{
  promoCode: Scalars["String"];
}>;

export type KaijinCampfireFastLanePassportTicketQuery = Pick<
  Query,
  "kaijinCampfireFastLanePassportTicket"
>;

export type RelatedPostsQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type RelatedPostsQuery = { relatedPosts: Array<PostInfoFragment> };

export type AllPostsQueryVariables = Exact<{
  page: Scalars["Int"];
  pageSize: Scalars["Int"];
}>;

export type AllPostsQuery = {
  allPosts: {
    pageInfo: SimplePageInfoFragment;
    nodes: Array<PostInfoFragment>;
  };
};

export type SearchPostsQueryVariables = Exact<{
  query: Scalars["String"];
  page: Scalars["Int"];
  pageSize: Scalars["Int"];
}>;

export type SearchPostsQuery = {
  searchPosts: {
    pageInfo: SimplePageInfoFragment;
    nodes: Array<PostInfoFragment>;
  };
};

export type ShopPostsQueryVariables = Exact<{
  shopId: Scalars["ID"];
  page: Scalars["Int"];
  pageSize: Scalars["Int"];
}>;

export type ShopPostsQuery = {
  shopPosts: {
    pageInfo: SimplePageInfoFragment;
    nodes: Array<PostInfoFragment>;
  };
};

export type TagPostsQueryVariables = Exact<{
  tag: Scalars["String"];
  page: Scalars["Int"];
  pageSize: Scalars["Int"];
}>;

export type TagPostsQuery = {
  tagPosts: {
    pageInfo: SimplePageInfoFragment;
    nodes: Array<PostInfoFragment>;
  };
};

export const SimplePageInfoFragmentDoc = gql`
  fragment simplePageInfo on SimplePageInfo {
    page
    totalPage
  }
`;
export const UserInfoFragmentDoc = gql`
  fragment userInfo on User {
    id
    userId
    email
    displayName
    screenImgUrl
  }
`;
export const UserPublicInfoFragmentDoc = gql`
  fragment userPublicInfo on User {
    id
    userId
    displayName
    screenImgUrl
  }
`;
export const ShopInfoFragmentDoc = gql`
  fragment shopInfo on Shop {
    id
    name
    screenImgUrl
    lat
    lng
    postalCode
    address
    access
    phoneNumber
    closeInfo
    tabelogRating
    tabelogUrl
    instagramHashtag
    lunchAveragePriceMin
    lunchAveragePriceMax
    dinnerAveragePriceMin
    dinnerAveragePriceMax
  }
`;
export const LunchHourlyWaitingTimeInfoFragmentDoc = gql`
  fragment lunchHourlyWaitingTimeInfo on HourlyWaitingTime {
    shopId
    hour
    averageWaitingPeopleNum
    averageWaitingTime
  }
`;
export const PostInfoFragmentDoc = gql`
  fragment postInfo on Post {
    id
    url
    title
    description
    screenImgUrl
  }
`;
export const CreateWaitingLineObservationDocument = gql`
  mutation createWaitingLineObservation(
    $input: CreateWaitingLineObservationInput
  ) {
    createWaitingLineObservation(input: $input)
  }
`;
export type CreateWaitingLineObservationMutationFn = Apollo.MutationFunction<
  CreateWaitingLineObservationMutation,
  CreateWaitingLineObservationMutationVariables
>;

/**
 * __useCreateWaitingLineObservationMutation__
 *
 * To run a mutation, you first call `useCreateWaitingLineObservationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWaitingLineObservationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWaitingLineObservationMutation, { data, loading, error }] = useCreateWaitingLineObservationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateWaitingLineObservationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateWaitingLineObservationMutation,
    CreateWaitingLineObservationMutationVariables
  >
) {
  return Apollo.useMutation<
    CreateWaitingLineObservationMutation,
    CreateWaitingLineObservationMutationVariables
  >(CreateWaitingLineObservationDocument, baseOptions);
}
export type CreateWaitingLineObservationMutationHookResult = ReturnType<
  typeof useCreateWaitingLineObservationMutation
>;
export type CreateWaitingLineObservationMutationResult = Apollo.MutationResult<CreateWaitingLineObservationMutation>;
export type CreateWaitingLineObservationMutationOptions = Apollo.BaseMutationOptions<
  CreateWaitingLineObservationMutation,
  CreateWaitingLineObservationMutationVariables
>;
export const CreateShopDocument = gql`
  mutation createShop($input: CreateShopInput!) {
    createShop(input: $input) {
      id
      name
    }
  }
`;
export type CreateShopMutationFn = Apollo.MutationFunction<
  CreateShopMutation,
  CreateShopMutationVariables
>;

/**
 * __useCreateShopMutation__
 *
 * To run a mutation, you first call `useCreateShopMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateShopMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createShopMutation, { data, loading, error }] = useCreateShopMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateShopMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateShopMutation,
    CreateShopMutationVariables
  >
) {
  return Apollo.useMutation<CreateShopMutation, CreateShopMutationVariables>(
    CreateShopDocument,
    baseOptions
  );
}
export type CreateShopMutationHookResult = ReturnType<
  typeof useCreateShopMutation
>;
export type CreateShopMutationResult = Apollo.MutationResult<CreateShopMutation>;
export type CreateShopMutationOptions = Apollo.BaseMutationOptions<
  CreateShopMutation,
  CreateShopMutationVariables
>;
export const UpdateShopDocument = gql`
  mutation updateShop($input: UpdateShopInput!) {
    updateShop(input: $input) {
      id
      name
    }
  }
`;
export type UpdateShopMutationFn = Apollo.MutationFunction<
  UpdateShopMutation,
  UpdateShopMutationVariables
>;

/**
 * __useUpdateShopMutation__
 *
 * To run a mutation, you first call `useUpdateShopMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateShopMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateShopMutation, { data, loading, error }] = useUpdateShopMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateShopMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateShopMutation,
    UpdateShopMutationVariables
  >
) {
  return Apollo.useMutation<UpdateShopMutation, UpdateShopMutationVariables>(
    UpdateShopDocument,
    baseOptions
  );
}
export type UpdateShopMutationHookResult = ReturnType<
  typeof useUpdateShopMutation
>;
export type UpdateShopMutationResult = Apollo.MutationResult<UpdateShopMutation>;
export type UpdateShopMutationOptions = Apollo.BaseMutationOptions<
  UpdateShopMutation,
  UpdateShopMutationVariables
>;
export const UpdateShopScreenImgDocument = gql`
  mutation updateShopScreenImg($input: UpdateShopScreenImgInput!) {
    updateShopScreenImg(input: $input)
  }
`;
export type UpdateShopScreenImgMutationFn = Apollo.MutationFunction<
  UpdateShopScreenImgMutation,
  UpdateShopScreenImgMutationVariables
>;

/**
 * __useUpdateShopScreenImgMutation__
 *
 * To run a mutation, you first call `useUpdateShopScreenImgMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateShopScreenImgMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateShopScreenImgMutation, { data, loading, error }] = useUpdateShopScreenImgMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateShopScreenImgMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateShopScreenImgMutation,
    UpdateShopScreenImgMutationVariables
  >
) {
  return Apollo.useMutation<
    UpdateShopScreenImgMutation,
    UpdateShopScreenImgMutationVariables
  >(UpdateShopScreenImgDocument, baseOptions);
}
export type UpdateShopScreenImgMutationHookResult = ReturnType<
  typeof useUpdateShopScreenImgMutation
>;
export type UpdateShopScreenImgMutationResult = Apollo.MutationResult<UpdateShopScreenImgMutation>;
export type UpdateShopScreenImgMutationOptions = Apollo.BaseMutationOptions<
  UpdateShopScreenImgMutation,
  UpdateShopScreenImgMutationVariables
>;
export const UploadWaitingLineObservationListCsvDocument = gql`
  mutation uploadWaitingLineObservationListCsv($file: Upload!) {
    uploadWaitingLineObservationListCsv(input: $file)
  }
`;
export type UploadWaitingLineObservationListCsvMutationFn = Apollo.MutationFunction<
  UploadWaitingLineObservationListCsvMutation,
  UploadWaitingLineObservationListCsvMutationVariables
>;

/**
 * __useUploadWaitingLineObservationListCsvMutation__
 *
 * To run a mutation, you first call `useUploadWaitingLineObservationListCsvMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadWaitingLineObservationListCsvMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadWaitingLineObservationListCsvMutation, { data, loading, error }] = useUploadWaitingLineObservationListCsvMutation({
 *   variables: {
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUploadWaitingLineObservationListCsvMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UploadWaitingLineObservationListCsvMutation,
    UploadWaitingLineObservationListCsvMutationVariables
  >
) {
  return Apollo.useMutation<
    UploadWaitingLineObservationListCsvMutation,
    UploadWaitingLineObservationListCsvMutationVariables
  >(UploadWaitingLineObservationListCsvDocument, baseOptions);
}
export type UploadWaitingLineObservationListCsvMutationHookResult = ReturnType<
  typeof useUploadWaitingLineObservationListCsvMutation
>;
export type UploadWaitingLineObservationListCsvMutationResult = Apollo.MutationResult<UploadWaitingLineObservationListCsvMutation>;
export type UploadWaitingLineObservationListCsvMutationOptions = Apollo.BaseMutationOptions<
  UploadWaitingLineObservationListCsvMutation,
  UploadWaitingLineObservationListCsvMutationVariables
>;
export const RegisterCreditCardDocument = gql`
  mutation registerCreditCard($cardToken: String!) {
    registerCreditCard(cardToken: $cardToken) {
      id
      brand
      last4
      expYear
      expMonth
      createdAt
    }
  }
`;
export type RegisterCreditCardMutationFn = Apollo.MutationFunction<
  RegisterCreditCardMutation,
  RegisterCreditCardMutationVariables
>;

/**
 * __useRegisterCreditCardMutation__
 *
 * To run a mutation, you first call `useRegisterCreditCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterCreditCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerCreditCardMutation, { data, loading, error }] = useRegisterCreditCardMutation({
 *   variables: {
 *      cardToken: // value for 'cardToken'
 *   },
 * });
 */
export function useRegisterCreditCardMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RegisterCreditCardMutation,
    RegisterCreditCardMutationVariables
  >
) {
  return Apollo.useMutation<
    RegisterCreditCardMutation,
    RegisterCreditCardMutationVariables
  >(RegisterCreditCardDocument, baseOptions);
}
export type RegisterCreditCardMutationHookResult = ReturnType<
  typeof useRegisterCreditCardMutation
>;
export type RegisterCreditCardMutationResult = Apollo.MutationResult<RegisterCreditCardMutation>;
export type RegisterCreditCardMutationOptions = Apollo.BaseMutationOptions<
  RegisterCreditCardMutation,
  RegisterCreditCardMutationVariables
>;
export const DeleteCreditCardDocument = gql`
  mutation deleteCreditCard($id: ID!) {
    deleteCreditCard(id: $id)
  }
`;
export type DeleteCreditCardMutationFn = Apollo.MutationFunction<
  DeleteCreditCardMutation,
  DeleteCreditCardMutationVariables
>;

/**
 * __useDeleteCreditCardMutation__
 *
 * To run a mutation, you first call `useDeleteCreditCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCreditCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCreditCardMutation, { data, loading, error }] = useDeleteCreditCardMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCreditCardMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteCreditCardMutation,
    DeleteCreditCardMutationVariables
  >
) {
  return Apollo.useMutation<
    DeleteCreditCardMutation,
    DeleteCreditCardMutationVariables
  >(DeleteCreditCardDocument, baseOptions);
}
export type DeleteCreditCardMutationHookResult = ReturnType<
  typeof useDeleteCreditCardMutation
>;
export type DeleteCreditCardMutationResult = Apollo.MutationResult<DeleteCreditCardMutation>;
export type DeleteCreditCardMutationOptions = Apollo.BaseMutationOptions<
  DeleteCreditCardMutation,
  DeleteCreditCardMutationVariables
>;
export const PayFastLaneDocument = gql`
  mutation payFastLane($input: PayFastLaneInput!) {
    payFastLane(input: $input) {
      id
      price
      userNum
      isEntered
      createdAt
      fastLane {
        shop {
          name
          screenImgUrl
        }
      }
    }
  }
`;
export type PayFastLaneMutationFn = Apollo.MutationFunction<
  PayFastLaneMutation,
  PayFastLaneMutationVariables
>;

/**
 * __usePayFastLaneMutation__
 *
 * To run a mutation, you first call `usePayFastLaneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePayFastLaneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [payFastLaneMutation, { data, loading, error }] = usePayFastLaneMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePayFastLaneMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PayFastLaneMutation,
    PayFastLaneMutationVariables
  >
) {
  return Apollo.useMutation<PayFastLaneMutation, PayFastLaneMutationVariables>(
    PayFastLaneDocument,
    baseOptions
  );
}
export type PayFastLaneMutationHookResult = ReturnType<
  typeof usePayFastLaneMutation
>;
export type PayFastLaneMutationResult = Apollo.MutationResult<PayFastLaneMutation>;
export type PayFastLaneMutationOptions = Apollo.BaseMutationOptions<
  PayFastLaneMutation,
  PayFastLaneMutationVariables
>;
export const UseKaijinCampfireFastLaneFreeTicketDocument = gql`
  mutation useKaijinCampfireFastLaneFreeTicket($promoCode: String!) {
    useKaijinCampfireFastLaneFreeTicket(promoCode: $promoCode)
  }
`;
export type UseKaijinCampfireFastLaneFreeTicketMutationFn = Apollo.MutationFunction<
  UseKaijinCampfireFastLaneFreeTicketMutation,
  UseKaijinCampfireFastLaneFreeTicketMutationVariables
>;

/**
 * __useUseKaijinCampfireFastLaneFreeTicketMutation__
 *
 * To run a mutation, you first call `useUseKaijinCampfireFastLaneFreeTicketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUseKaijinCampfireFastLaneFreeTicketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [useKaijinCampfireFastLaneFreeTicketMutation, { data, loading, error }] = useUseKaijinCampfireFastLaneFreeTicketMutation({
 *   variables: {
 *      promoCode: // value for 'promoCode'
 *   },
 * });
 */
export function useUseKaijinCampfireFastLaneFreeTicketMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UseKaijinCampfireFastLaneFreeTicketMutation,
    UseKaijinCampfireFastLaneFreeTicketMutationVariables
  >
) {
  return Apollo.useMutation<
    UseKaijinCampfireFastLaneFreeTicketMutation,
    UseKaijinCampfireFastLaneFreeTicketMutationVariables
  >(UseKaijinCampfireFastLaneFreeTicketDocument, baseOptions);
}
export type UseKaijinCampfireFastLaneFreeTicketMutationHookResult = ReturnType<
  typeof useUseKaijinCampfireFastLaneFreeTicketMutation
>;
export type UseKaijinCampfireFastLaneFreeTicketMutationResult = Apollo.MutationResult<UseKaijinCampfireFastLaneFreeTicketMutation>;
export type UseKaijinCampfireFastLaneFreeTicketMutationOptions = Apollo.BaseMutationOptions<
  UseKaijinCampfireFastLaneFreeTicketMutation,
  UseKaijinCampfireFastLaneFreeTicketMutationVariables
>;
export const UseKaijinCampfireFastLanePassportTicketDocument = gql`
  mutation useKaijinCampfireFastLanePassportTicket($promoCode: String!) {
    useKaijinCampfireFastLanePassportTicket(promoCode: $promoCode)
  }
`;
export type UseKaijinCampfireFastLanePassportTicketMutationFn = Apollo.MutationFunction<
  UseKaijinCampfireFastLanePassportTicketMutation,
  UseKaijinCampfireFastLanePassportTicketMutationVariables
>;

/**
 * __useUseKaijinCampfireFastLanePassportTicketMutation__
 *
 * To run a mutation, you first call `useUseKaijinCampfireFastLanePassportTicketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUseKaijinCampfireFastLanePassportTicketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [useKaijinCampfireFastLanePassportTicketMutation, { data, loading, error }] = useUseKaijinCampfireFastLanePassportTicketMutation({
 *   variables: {
 *      promoCode: // value for 'promoCode'
 *   },
 * });
 */
export function useUseKaijinCampfireFastLanePassportTicketMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UseKaijinCampfireFastLanePassportTicketMutation,
    UseKaijinCampfireFastLanePassportTicketMutationVariables
  >
) {
  return Apollo.useMutation<
    UseKaijinCampfireFastLanePassportTicketMutation,
    UseKaijinCampfireFastLanePassportTicketMutationVariables
  >(UseKaijinCampfireFastLanePassportTicketDocument, baseOptions);
}
export type UseKaijinCampfireFastLanePassportTicketMutationHookResult = ReturnType<
  typeof useUseKaijinCampfireFastLanePassportTicketMutation
>;
export type UseKaijinCampfireFastLanePassportTicketMutationResult = Apollo.MutationResult<UseKaijinCampfireFastLanePassportTicketMutation>;
export type UseKaijinCampfireFastLanePassportTicketMutationOptions = Apollo.BaseMutationOptions<
  UseKaijinCampfireFastLanePassportTicketMutation,
  UseKaijinCampfireFastLanePassportTicketMutationVariables
>;
export const UseFastLanePassportDocument = gql`
  mutation useFastLanePassport($fastLaneId: ID!, $userFastLanePassportId: ID!) {
    useFastLanePassport(
      fastLaneId: $fastLaneId
      userFastLanePassportId: $userFastLanePassportId
    ) {
      id
    }
  }
`;
export type UseFastLanePassportMutationFn = Apollo.MutationFunction<
  UseFastLanePassportMutation,
  UseFastLanePassportMutationVariables
>;

/**
 * __useUseFastLanePassportMutation__
 *
 * To run a mutation, you first call `useUseFastLanePassportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUseFastLanePassportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [useFastLanePassportMutation, { data, loading, error }] = useUseFastLanePassportMutation({
 *   variables: {
 *      fastLaneId: // value for 'fastLaneId'
 *      userFastLanePassportId: // value for 'userFastLanePassportId'
 *   },
 * });
 */
export function useUseFastLanePassportMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UseFastLanePassportMutation,
    UseFastLanePassportMutationVariables
  >
) {
  return Apollo.useMutation<
    UseFastLanePassportMutation,
    UseFastLanePassportMutationVariables
  >(UseFastLanePassportDocument, baseOptions);
}
export type UseFastLanePassportMutationHookResult = ReturnType<
  typeof useUseFastLanePassportMutation
>;
export type UseFastLanePassportMutationResult = Apollo.MutationResult<UseFastLanePassportMutation>;
export type UseFastLanePassportMutationOptions = Apollo.BaseMutationOptions<
  UseFastLanePassportMutation,
  UseFastLanePassportMutationVariables
>;
export const EnterShopDocument = gql`
  mutation enterShop($fastLaneUsageHistoryId: ID!) {
    enterShop(fastLaneUsageHistoryId: $fastLaneUsageHistoryId)
  }
`;
export type EnterShopMutationFn = Apollo.MutationFunction<
  EnterShopMutation,
  EnterShopMutationVariables
>;

/**
 * __useEnterShopMutation__
 *
 * To run a mutation, you first call `useEnterShopMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnterShopMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enterShopMutation, { data, loading, error }] = useEnterShopMutation({
 *   variables: {
 *      fastLaneUsageHistoryId: // value for 'fastLaneUsageHistoryId'
 *   },
 * });
 */
export function useEnterShopMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EnterShopMutation,
    EnterShopMutationVariables
  >
) {
  return Apollo.useMutation<EnterShopMutation, EnterShopMutationVariables>(
    EnterShopDocument,
    baseOptions
  );
}
export type EnterShopMutationHookResult = ReturnType<
  typeof useEnterShopMutation
>;
export type EnterShopMutationResult = Apollo.MutationResult<EnterShopMutation>;
export type EnterShopMutationOptions = Apollo.BaseMutationOptions<
  EnterShopMutation,
  EnterShopMutationVariables
>;
export const CurrentUserDocument = gql`
  query currentUser {
    currentUser {
      ...userInfo
    }
  }
  ${UserInfoFragmentDoc}
`;

/**
 * __useCurrentUserQuery__
 *
 * To run a query within a React component, call `useCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CurrentUserQuery,
    CurrentUserQueryVariables
  >
) {
  return Apollo.useQuery<CurrentUserQuery, CurrentUserQueryVariables>(
    CurrentUserDocument,
    baseOptions
  );
}
export function useCurrentUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CurrentUserQuery,
    CurrentUserQueryVariables
  >
) {
  return Apollo.useLazyQuery<CurrentUserQuery, CurrentUserQueryVariables>(
    CurrentUserDocument,
    baseOptions
  );
}
export type CurrentUserQueryHookResult = ReturnType<typeof useCurrentUserQuery>;
export type CurrentUserLazyQueryHookResult = ReturnType<
  typeof useCurrentUserLazyQuery
>;
export type CurrentUserQueryResult = Apollo.QueryResult<
  CurrentUserQuery,
  CurrentUserQueryVariables
>;
export const UserCreditCardsDocument = gql`
  query userCreditCards {
    userCreditCards {
      id
      brand
      last4
      expYear
      expMonth
      createdAt
    }
  }
`;

/**
 * __useUserCreditCardsQuery__
 *
 * To run a query within a React component, call `useUserCreditCardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserCreditCardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserCreditCardsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserCreditCardsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    UserCreditCardsQuery,
    UserCreditCardsQueryVariables
  >
) {
  return Apollo.useQuery<UserCreditCardsQuery, UserCreditCardsQueryVariables>(
    UserCreditCardsDocument,
    baseOptions
  );
}
export function useUserCreditCardsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserCreditCardsQuery,
    UserCreditCardsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    UserCreditCardsQuery,
    UserCreditCardsQueryVariables
  >(UserCreditCardsDocument, baseOptions);
}
export type UserCreditCardsQueryHookResult = ReturnType<
  typeof useUserCreditCardsQuery
>;
export type UserCreditCardsLazyQueryHookResult = ReturnType<
  typeof useUserCreditCardsLazyQuery
>;
export type UserCreditCardsQueryResult = Apollo.QueryResult<
  UserCreditCardsQuery,
  UserCreditCardsQueryVariables
>;
export const AllShopsDocument = gql`
  query allShops {
    allShops {
      id
      name
      smallArea {
        id
        name
        middleArea {
          id
        }
      }
    }
  }
`;

/**
 * __useAllShopsQuery__
 *
 * To run a query within a React component, call `useAllShopsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllShopsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllShopsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllShopsQuery(
  baseOptions?: Apollo.QueryHookOptions<AllShopsQuery, AllShopsQueryVariables>
) {
  return Apollo.useQuery<AllShopsQuery, AllShopsQueryVariables>(
    AllShopsDocument,
    baseOptions
  );
}
export function useAllShopsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllShopsQuery,
    AllShopsQueryVariables
  >
) {
  return Apollo.useLazyQuery<AllShopsQuery, AllShopsQueryVariables>(
    AllShopsDocument,
    baseOptions
  );
}
export type AllShopsQueryHookResult = ReturnType<typeof useAllShopsQuery>;
export type AllShopsLazyQueryHookResult = ReturnType<
  typeof useAllShopsLazyQuery
>;
export type AllShopsQueryResult = Apollo.QueryResult<
  AllShopsQuery,
  AllShopsQueryVariables
>;
export const AllShopIdsDocument = gql`
  query allShopIds {
    allShopIds
  }
`;

/**
 * __useAllShopIdsQuery__
 *
 * To run a query within a React component, call `useAllShopIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllShopIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllShopIdsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllShopIdsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AllShopIdsQuery,
    AllShopIdsQueryVariables
  >
) {
  return Apollo.useQuery<AllShopIdsQuery, AllShopIdsQueryVariables>(
    AllShopIdsDocument,
    baseOptions
  );
}
export function useAllShopIdsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllShopIdsQuery,
    AllShopIdsQueryVariables
  >
) {
  return Apollo.useLazyQuery<AllShopIdsQuery, AllShopIdsQueryVariables>(
    AllShopIdsDocument,
    baseOptions
  );
}
export type AllShopIdsQueryHookResult = ReturnType<typeof useAllShopIdsQuery>;
export type AllShopIdsLazyQueryHookResult = ReturnType<
  typeof useAllShopIdsLazyQuery
>;
export type AllShopIdsQueryResult = Apollo.QueryResult<
  AllShopIdsQuery,
  AllShopIdsQueryVariables
>;
export const PrefectureDocument = gql`
  query prefecture($id: ID!) {
    prefecture(id: $id) {
      name
      middleAreas {
        id
        name
        smallAreas {
          id
          name
        }
      }
      stations {
        id
        name
        railwayLineName
        lat
        lng
        sortNum
      }
    }
  }
`;

/**
 * __usePrefectureQuery__
 *
 * To run a query within a React component, call `usePrefectureQuery` and pass it any options that fit your needs.
 * When your component renders, `usePrefectureQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePrefectureQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePrefectureQuery(
  baseOptions: Apollo.QueryHookOptions<
    PrefectureQuery,
    PrefectureQueryVariables
  >
) {
  return Apollo.useQuery<PrefectureQuery, PrefectureQueryVariables>(
    PrefectureDocument,
    baseOptions
  );
}
export function usePrefectureLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PrefectureQuery,
    PrefectureQueryVariables
  >
) {
  return Apollo.useLazyQuery<PrefectureQuery, PrefectureQueryVariables>(
    PrefectureDocument,
    baseOptions
  );
}
export type PrefectureQueryHookResult = ReturnType<typeof usePrefectureQuery>;
export type PrefectureLazyQueryHookResult = ReturnType<
  typeof usePrefectureLazyQuery
>;
export type PrefectureQueryResult = Apollo.QueryResult<
  PrefectureQuery,
  PrefectureQueryVariables
>;
export const SmallAreaDocument = gql`
  query smallArea($id: ID!) {
    smallArea(id: $id) {
      name
      shops {
        ...shopInfo
        lunchHourlyWaitingTimes {
          ...lunchHourlyWaitingTimeInfo
        }
        station {
          name
          lat
          lng
        }
      }
    }
  }
  ${ShopInfoFragmentDoc}
  ${LunchHourlyWaitingTimeInfoFragmentDoc}
`;

/**
 * __useSmallAreaQuery__
 *
 * To run a query within a React component, call `useSmallAreaQuery` and pass it any options that fit your needs.
 * When your component renders, `useSmallAreaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSmallAreaQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSmallAreaQuery(
  baseOptions: Apollo.QueryHookOptions<SmallAreaQuery, SmallAreaQueryVariables>
) {
  return Apollo.useQuery<SmallAreaQuery, SmallAreaQueryVariables>(
    SmallAreaDocument,
    baseOptions
  );
}
export function useSmallAreaLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SmallAreaQuery,
    SmallAreaQueryVariables
  >
) {
  return Apollo.useLazyQuery<SmallAreaQuery, SmallAreaQueryVariables>(
    SmallAreaDocument,
    baseOptions
  );
}
export type SmallAreaQueryHookResult = ReturnType<typeof useSmallAreaQuery>;
export type SmallAreaLazyQueryHookResult = ReturnType<
  typeof useSmallAreaLazyQuery
>;
export type SmallAreaQueryResult = Apollo.QueryResult<
  SmallAreaQuery,
  SmallAreaQueryVariables
>;
export const ShopDocument = gql`
  query shop($id: ID!) {
    shop(id: $id) {
      ...shopInfo
      smallArea {
        id
        name
        middleArea {
          id
          prefecture {
            id
          }
        }
      }
      station {
        id
        name
        railwayLineName
      }
      lunchHourlyWaitingTimes {
        ...lunchHourlyWaitingTimeInfo
      }
    }
  }
  ${ShopInfoFragmentDoc}
  ${LunchHourlyWaitingTimeInfoFragmentDoc}
`;

/**
 * __useShopQuery__
 *
 * To run a query within a React component, call `useShopQuery` and pass it any options that fit your needs.
 * When your component renders, `useShopQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShopQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useShopQuery(
  baseOptions: Apollo.QueryHookOptions<ShopQuery, ShopQueryVariables>
) {
  return Apollo.useQuery<ShopQuery, ShopQueryVariables>(
    ShopDocument,
    baseOptions
  );
}
export function useShopLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ShopQuery, ShopQueryVariables>
) {
  return Apollo.useLazyQuery<ShopQuery, ShopQueryVariables>(
    ShopDocument,
    baseOptions
  );
}
export type ShopQueryHookResult = ReturnType<typeof useShopQuery>;
export type ShopLazyQueryHookResult = ReturnType<typeof useShopLazyQuery>;
export type ShopQueryResult = Apollo.QueryResult<ShopQuery, ShopQueryVariables>;
export const ShopInfoCandidatesDocument = gql`
  query shopInfoCandidates($q: String!) {
    shopInfoCandidates(q: $q) {
      ...shopInfo
    }
  }
  ${ShopInfoFragmentDoc}
`;

/**
 * __useShopInfoCandidatesQuery__
 *
 * To run a query within a React component, call `useShopInfoCandidatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useShopInfoCandidatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShopInfoCandidatesQuery({
 *   variables: {
 *      q: // value for 'q'
 *   },
 * });
 */
export function useShopInfoCandidatesQuery(
  baseOptions: Apollo.QueryHookOptions<
    ShopInfoCandidatesQuery,
    ShopInfoCandidatesQueryVariables
  >
) {
  return Apollo.useQuery<
    ShopInfoCandidatesQuery,
    ShopInfoCandidatesQueryVariables
  >(ShopInfoCandidatesDocument, baseOptions);
}
export function useShopInfoCandidatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ShopInfoCandidatesQuery,
    ShopInfoCandidatesQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    ShopInfoCandidatesQuery,
    ShopInfoCandidatesQueryVariables
  >(ShopInfoCandidatesDocument, baseOptions);
}
export type ShopInfoCandidatesQueryHookResult = ReturnType<
  typeof useShopInfoCandidatesQuery
>;
export type ShopInfoCandidatesLazyQueryHookResult = ReturnType<
  typeof useShopInfoCandidatesLazyQuery
>;
export type ShopInfoCandidatesQueryResult = Apollo.QueryResult<
  ShopInfoCandidatesQuery,
  ShopInfoCandidatesQueryVariables
>;
export const ShopInfoDocument = gql`
  query shopInfo($id: ID!) {
    shopInfo(id: $id) {
      ...shopInfo
    }
  }
  ${ShopInfoFragmentDoc}
`;

/**
 * __useShopInfoQuery__
 *
 * To run a query within a React component, call `useShopInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useShopInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShopInfoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useShopInfoQuery(
  baseOptions: Apollo.QueryHookOptions<ShopInfoQuery, ShopInfoQueryVariables>
) {
  return Apollo.useQuery<ShopInfoQuery, ShopInfoQueryVariables>(
    ShopInfoDocument,
    baseOptions
  );
}
export function useShopInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ShopInfoQuery,
    ShopInfoQueryVariables
  >
) {
  return Apollo.useLazyQuery<ShopInfoQuery, ShopInfoQueryVariables>(
    ShopInfoDocument,
    baseOptions
  );
}
export type ShopInfoQueryHookResult = ReturnType<typeof useShopInfoQuery>;
export type ShopInfoLazyQueryHookResult = ReturnType<
  typeof useShopInfoLazyQuery
>;
export type ShopInfoQueryResult = Apollo.QueryResult<
  ShopInfoQuery,
  ShopInfoQueryVariables
>;
export const AllWaitingLineObservationsDocument = gql`
  query allWaitingLineObservations {
    allWaitingLineObservations {
      id
      shopId
      waitingTime
      waitingPeopleNum
      observedBy
      observedAt
      createdAt
    }
  }
`;

/**
 * __useAllWaitingLineObservationsQuery__
 *
 * To run a query within a React component, call `useAllWaitingLineObservationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllWaitingLineObservationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllWaitingLineObservationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllWaitingLineObservationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AllWaitingLineObservationsQuery,
    AllWaitingLineObservationsQueryVariables
  >
) {
  return Apollo.useQuery<
    AllWaitingLineObservationsQuery,
    AllWaitingLineObservationsQueryVariables
  >(AllWaitingLineObservationsDocument, baseOptions);
}
export function useAllWaitingLineObservationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllWaitingLineObservationsQuery,
    AllWaitingLineObservationsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    AllWaitingLineObservationsQuery,
    AllWaitingLineObservationsQueryVariables
  >(AllWaitingLineObservationsDocument, baseOptions);
}
export type AllWaitingLineObservationsQueryHookResult = ReturnType<
  typeof useAllWaitingLineObservationsQuery
>;
export type AllWaitingLineObservationsLazyQueryHookResult = ReturnType<
  typeof useAllWaitingLineObservationsLazyQuery
>;
export type AllWaitingLineObservationsQueryResult = Apollo.QueryResult<
  AllWaitingLineObservationsQuery,
  AllWaitingLineObservationsQueryVariables
>;
export const ShopWaitingLineObservationsWithImgDocument = gql`
  query shopWaitingLineObservationsWithImg($shopId: ID!) {
    shopWaitingLineObservationsWithImg(shopId: $shopId) {
      imgUrl
    }
  }
`;

/**
 * __useShopWaitingLineObservationsWithImgQuery__
 *
 * To run a query within a React component, call `useShopWaitingLineObservationsWithImgQuery` and pass it any options that fit your needs.
 * When your component renders, `useShopWaitingLineObservationsWithImgQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShopWaitingLineObservationsWithImgQuery({
 *   variables: {
 *      shopId: // value for 'shopId'
 *   },
 * });
 */
export function useShopWaitingLineObservationsWithImgQuery(
  baseOptions: Apollo.QueryHookOptions<
    ShopWaitingLineObservationsWithImgQuery,
    ShopWaitingLineObservationsWithImgQueryVariables
  >
) {
  return Apollo.useQuery<
    ShopWaitingLineObservationsWithImgQuery,
    ShopWaitingLineObservationsWithImgQueryVariables
  >(ShopWaitingLineObservationsWithImgDocument, baseOptions);
}
export function useShopWaitingLineObservationsWithImgLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ShopWaitingLineObservationsWithImgQuery,
    ShopWaitingLineObservationsWithImgQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    ShopWaitingLineObservationsWithImgQuery,
    ShopWaitingLineObservationsWithImgQueryVariables
  >(ShopWaitingLineObservationsWithImgDocument, baseOptions);
}
export type ShopWaitingLineObservationsWithImgQueryHookResult = ReturnType<
  typeof useShopWaitingLineObservationsWithImgQuery
>;
export type ShopWaitingLineObservationsWithImgLazyQueryHookResult = ReturnType<
  typeof useShopWaitingLineObservationsWithImgLazyQuery
>;
export type ShopWaitingLineObservationsWithImgQueryResult = Apollo.QueryResult<
  ShopWaitingLineObservationsWithImgQuery,
  ShopWaitingLineObservationsWithImgQueryVariables
>;
export const UserWaitingLineObservationsDocument = gql`
  query userWaitingLineObservations($userId: ID!) {
    userWaitingLineObservations(userId: $userId) {
      id
      waitingTime
      waitingPeopleNum
      observedAt
      imgUrl
      shop {
        id
        name
        screenImgUrl
      }
    }
  }
`;

/**
 * __useUserWaitingLineObservationsQuery__
 *
 * To run a query within a React component, call `useUserWaitingLineObservationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserWaitingLineObservationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserWaitingLineObservationsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserWaitingLineObservationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    UserWaitingLineObservationsQuery,
    UserWaitingLineObservationsQueryVariables
  >
) {
  return Apollo.useQuery<
    UserWaitingLineObservationsQuery,
    UserWaitingLineObservationsQueryVariables
  >(UserWaitingLineObservationsDocument, baseOptions);
}
export function useUserWaitingLineObservationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserWaitingLineObservationsQuery,
    UserWaitingLineObservationsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    UserWaitingLineObservationsQuery,
    UserWaitingLineObservationsQueryVariables
  >(UserWaitingLineObservationsDocument, baseOptions);
}
export type UserWaitingLineObservationsQueryHookResult = ReturnType<
  typeof useUserWaitingLineObservationsQuery
>;
export type UserWaitingLineObservationsLazyQueryHookResult = ReturnType<
  typeof useUserWaitingLineObservationsLazyQuery
>;
export type UserWaitingLineObservationsQueryResult = Apollo.QueryResult<
  UserWaitingLineObservationsQuery,
  UserWaitingLineObservationsQueryVariables
>;
export const FastLaneDocument = gql`
  query fastLane($shopId: ID!) {
    fastLane(shopId: $shopId) {
      id
      status
      defaultPrice
      maxUserNum
      createdAt
      updatedAt
      currentPrice {
        price
        priceHash
        calculatedAt
        calculatedAtHash
        usedCountInLast30Minutes
      }
      shop {
        ...shopInfo
      }
    }
  }
  ${ShopInfoFragmentDoc}
`;

/**
 * __useFastLaneQuery__
 *
 * To run a query within a React component, call `useFastLaneQuery` and pass it any options that fit your needs.
 * When your component renders, `useFastLaneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFastLaneQuery({
 *   variables: {
 *      shopId: // value for 'shopId'
 *   },
 * });
 */
export function useFastLaneQuery(
  baseOptions: Apollo.QueryHookOptions<FastLaneQuery, FastLaneQueryVariables>
) {
  return Apollo.useQuery<FastLaneQuery, FastLaneQueryVariables>(
    FastLaneDocument,
    baseOptions
  );
}
export function useFastLaneLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FastLaneQuery,
    FastLaneQueryVariables
  >
) {
  return Apollo.useLazyQuery<FastLaneQuery, FastLaneQueryVariables>(
    FastLaneDocument,
    baseOptions
  );
}
export type FastLaneQueryHookResult = ReturnType<typeof useFastLaneQuery>;
export type FastLaneLazyQueryHookResult = ReturnType<
  typeof useFastLaneLazyQuery
>;
export type FastLaneQueryResult = Apollo.QueryResult<
  FastLaneQuery,
  FastLaneQueryVariables
>;
export const FastLaneUsageHistoryDocument = gql`
  query fastLaneUsageHistory($id: ID!) {
    fastLaneUsageHistory(id: $id) {
      id
      price
      userNum
      isEntered
      createdAt
      fastLane {
        shop {
          id
          name
          screenImgUrl
        }
      }
      charge {
        amount
        receiptUrl
      }
    }
  }
`;

/**
 * __useFastLaneUsageHistoryQuery__
 *
 * To run a query within a React component, call `useFastLaneUsageHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useFastLaneUsageHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFastLaneUsageHistoryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFastLaneUsageHistoryQuery(
  baseOptions: Apollo.QueryHookOptions<
    FastLaneUsageHistoryQuery,
    FastLaneUsageHistoryQueryVariables
  >
) {
  return Apollo.useQuery<
    FastLaneUsageHistoryQuery,
    FastLaneUsageHistoryQueryVariables
  >(FastLaneUsageHistoryDocument, baseOptions);
}
export function useFastLaneUsageHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FastLaneUsageHistoryQuery,
    FastLaneUsageHistoryQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    FastLaneUsageHistoryQuery,
    FastLaneUsageHistoryQueryVariables
  >(FastLaneUsageHistoryDocument, baseOptions);
}
export type FastLaneUsageHistoryQueryHookResult = ReturnType<
  typeof useFastLaneUsageHistoryQuery
>;
export type FastLaneUsageHistoryLazyQueryHookResult = ReturnType<
  typeof useFastLaneUsageHistoryLazyQuery
>;
export type FastLaneUsageHistoryQueryResult = Apollo.QueryResult<
  FastLaneUsageHistoryQuery,
  FastLaneUsageHistoryQueryVariables
>;
export const UserFastLaneUsageHistoriesDocument = gql`
  query userFastLaneUsageHistories {
    userFastLaneUsageHistories {
      id
      isEntered
      createdAt
      fastLane {
        shop {
          name
          screenImgUrl
        }
      }
    }
  }
`;

/**
 * __useUserFastLaneUsageHistoriesQuery__
 *
 * To run a query within a React component, call `useUserFastLaneUsageHistoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserFastLaneUsageHistoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserFastLaneUsageHistoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserFastLaneUsageHistoriesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    UserFastLaneUsageHistoriesQuery,
    UserFastLaneUsageHistoriesQueryVariables
  >
) {
  return Apollo.useQuery<
    UserFastLaneUsageHistoriesQuery,
    UserFastLaneUsageHistoriesQueryVariables
  >(UserFastLaneUsageHistoriesDocument, baseOptions);
}
export function useUserFastLaneUsageHistoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserFastLaneUsageHistoriesQuery,
    UserFastLaneUsageHistoriesQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    UserFastLaneUsageHistoriesQuery,
    UserFastLaneUsageHistoriesQueryVariables
  >(UserFastLaneUsageHistoriesDocument, baseOptions);
}
export type UserFastLaneUsageHistoriesQueryHookResult = ReturnType<
  typeof useUserFastLaneUsageHistoriesQuery
>;
export type UserFastLaneUsageHistoriesLazyQueryHookResult = ReturnType<
  typeof useUserFastLaneUsageHistoriesLazyQuery
>;
export type UserFastLaneUsageHistoriesQueryResult = Apollo.QueryResult<
  UserFastLaneUsageHistoriesQuery,
  UserFastLaneUsageHistoriesQueryVariables
>;
export const UserActiveFastLanePassportsDocument = gql`
  query userActiveFastLanePassports {
    userActiveFastLanePassports {
      id
      startsAt
      endsAt
      fastLanePassport {
        name
        targetType
        fastLanes {
          shop {
            id
            name
            screenImgUrl
          }
        }
      }
    }
  }
`;

/**
 * __useUserActiveFastLanePassportsQuery__
 *
 * To run a query within a React component, call `useUserActiveFastLanePassportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserActiveFastLanePassportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserActiveFastLanePassportsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserActiveFastLanePassportsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    UserActiveFastLanePassportsQuery,
    UserActiveFastLanePassportsQueryVariables
  >
) {
  return Apollo.useQuery<
    UserActiveFastLanePassportsQuery,
    UserActiveFastLanePassportsQueryVariables
  >(UserActiveFastLanePassportsDocument, baseOptions);
}
export function useUserActiveFastLanePassportsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserActiveFastLanePassportsQuery,
    UserActiveFastLanePassportsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    UserActiveFastLanePassportsQuery,
    UserActiveFastLanePassportsQueryVariables
  >(UserActiveFastLanePassportsDocument, baseOptions);
}
export type UserActiveFastLanePassportsQueryHookResult = ReturnType<
  typeof useUserActiveFastLanePassportsQuery
>;
export type UserActiveFastLanePassportsLazyQueryHookResult = ReturnType<
  typeof useUserActiveFastLanePassportsLazyQuery
>;
export type UserActiveFastLanePassportsQueryResult = Apollo.QueryResult<
  UserActiveFastLanePassportsQuery,
  UserActiveFastLanePassportsQueryVariables
>;
export const UserAvailableFastLanePassportByFastLaneIdDocument = gql`
  query userAvailableFastLanePassportByFastLaneID($fastLaneId: ID!) {
    userAvailableFastLanePassportByFastLaneID(fastLaneId: $fastLaneId) {
      id
      fastLanePassport {
        name
      }
    }
  }
`;

/**
 * __useUserAvailableFastLanePassportByFastLaneIdQuery__
 *
 * To run a query within a React component, call `useUserAvailableFastLanePassportByFastLaneIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserAvailableFastLanePassportByFastLaneIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserAvailableFastLanePassportByFastLaneIdQuery({
 *   variables: {
 *      fastLaneId: // value for 'fastLaneId'
 *   },
 * });
 */
export function useUserAvailableFastLanePassportByFastLaneIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    UserAvailableFastLanePassportByFastLaneIdQuery,
    UserAvailableFastLanePassportByFastLaneIdQueryVariables
  >
) {
  return Apollo.useQuery<
    UserAvailableFastLanePassportByFastLaneIdQuery,
    UserAvailableFastLanePassportByFastLaneIdQueryVariables
  >(UserAvailableFastLanePassportByFastLaneIdDocument, baseOptions);
}
export function useUserAvailableFastLanePassportByFastLaneIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserAvailableFastLanePassportByFastLaneIdQuery,
    UserAvailableFastLanePassportByFastLaneIdQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    UserAvailableFastLanePassportByFastLaneIdQuery,
    UserAvailableFastLanePassportByFastLaneIdQueryVariables
  >(UserAvailableFastLanePassportByFastLaneIdDocument, baseOptions);
}
export type UserAvailableFastLanePassportByFastLaneIdQueryHookResult = ReturnType<
  typeof useUserAvailableFastLanePassportByFastLaneIdQuery
>;
export type UserAvailableFastLanePassportByFastLaneIdLazyQueryHookResult = ReturnType<
  typeof useUserAvailableFastLanePassportByFastLaneIdLazyQuery
>;
export type UserAvailableFastLanePassportByFastLaneIdQueryResult = Apollo.QueryResult<
  UserAvailableFastLanePassportByFastLaneIdQuery,
  UserAvailableFastLanePassportByFastLaneIdQueryVariables
>;
export const KaijinCampfireFastLaneFreeTicketDocument = gql`
  query kaijinCampfireFastLaneFreeTicket($promoCode: String!) {
    kaijinCampfireFastLaneFreeTicket(promoCode: $promoCode)
  }
`;

/**
 * __useKaijinCampfireFastLaneFreeTicketQuery__
 *
 * To run a query within a React component, call `useKaijinCampfireFastLaneFreeTicketQuery` and pass it any options that fit your needs.
 * When your component renders, `useKaijinCampfireFastLaneFreeTicketQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKaijinCampfireFastLaneFreeTicketQuery({
 *   variables: {
 *      promoCode: // value for 'promoCode'
 *   },
 * });
 */
export function useKaijinCampfireFastLaneFreeTicketQuery(
  baseOptions: Apollo.QueryHookOptions<
    KaijinCampfireFastLaneFreeTicketQuery,
    KaijinCampfireFastLaneFreeTicketQueryVariables
  >
) {
  return Apollo.useQuery<
    KaijinCampfireFastLaneFreeTicketQuery,
    KaijinCampfireFastLaneFreeTicketQueryVariables
  >(KaijinCampfireFastLaneFreeTicketDocument, baseOptions);
}
export function useKaijinCampfireFastLaneFreeTicketLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    KaijinCampfireFastLaneFreeTicketQuery,
    KaijinCampfireFastLaneFreeTicketQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    KaijinCampfireFastLaneFreeTicketQuery,
    KaijinCampfireFastLaneFreeTicketQueryVariables
  >(KaijinCampfireFastLaneFreeTicketDocument, baseOptions);
}
export type KaijinCampfireFastLaneFreeTicketQueryHookResult = ReturnType<
  typeof useKaijinCampfireFastLaneFreeTicketQuery
>;
export type KaijinCampfireFastLaneFreeTicketLazyQueryHookResult = ReturnType<
  typeof useKaijinCampfireFastLaneFreeTicketLazyQuery
>;
export type KaijinCampfireFastLaneFreeTicketQueryResult = Apollo.QueryResult<
  KaijinCampfireFastLaneFreeTicketQuery,
  KaijinCampfireFastLaneFreeTicketQueryVariables
>;
export const KaijinCampfireFastLanePassportTicketDocument = gql`
  query kaijinCampfireFastLanePassportTicket($promoCode: String!) {
    kaijinCampfireFastLanePassportTicket(promoCode: $promoCode)
  }
`;

/**
 * __useKaijinCampfireFastLanePassportTicketQuery__
 *
 * To run a query within a React component, call `useKaijinCampfireFastLanePassportTicketQuery` and pass it any options that fit your needs.
 * When your component renders, `useKaijinCampfireFastLanePassportTicketQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKaijinCampfireFastLanePassportTicketQuery({
 *   variables: {
 *      promoCode: // value for 'promoCode'
 *   },
 * });
 */
export function useKaijinCampfireFastLanePassportTicketQuery(
  baseOptions: Apollo.QueryHookOptions<
    KaijinCampfireFastLanePassportTicketQuery,
    KaijinCampfireFastLanePassportTicketQueryVariables
  >
) {
  return Apollo.useQuery<
    KaijinCampfireFastLanePassportTicketQuery,
    KaijinCampfireFastLanePassportTicketQueryVariables
  >(KaijinCampfireFastLanePassportTicketDocument, baseOptions);
}
export function useKaijinCampfireFastLanePassportTicketLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    KaijinCampfireFastLanePassportTicketQuery,
    KaijinCampfireFastLanePassportTicketQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    KaijinCampfireFastLanePassportTicketQuery,
    KaijinCampfireFastLanePassportTicketQueryVariables
  >(KaijinCampfireFastLanePassportTicketDocument, baseOptions);
}
export type KaijinCampfireFastLanePassportTicketQueryHookResult = ReturnType<
  typeof useKaijinCampfireFastLanePassportTicketQuery
>;
export type KaijinCampfireFastLanePassportTicketLazyQueryHookResult = ReturnType<
  typeof useKaijinCampfireFastLanePassportTicketLazyQuery
>;
export type KaijinCampfireFastLanePassportTicketQueryResult = Apollo.QueryResult<
  KaijinCampfireFastLanePassportTicketQuery,
  KaijinCampfireFastLanePassportTicketQueryVariables
>;
export const RelatedPostsDocument = gql`
  query relatedPosts($id: ID!) {
    relatedPosts(id: $id) {
      ...postInfo
    }
  }
  ${PostInfoFragmentDoc}
`;

/**
 * __useRelatedPostsQuery__
 *
 * To run a query within a React component, call `useRelatedPostsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRelatedPostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRelatedPostsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRelatedPostsQuery(
  baseOptions: Apollo.QueryHookOptions<
    RelatedPostsQuery,
    RelatedPostsQueryVariables
  >
) {
  return Apollo.useQuery<RelatedPostsQuery, RelatedPostsQueryVariables>(
    RelatedPostsDocument,
    baseOptions
  );
}
export function useRelatedPostsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RelatedPostsQuery,
    RelatedPostsQueryVariables
  >
) {
  return Apollo.useLazyQuery<RelatedPostsQuery, RelatedPostsQueryVariables>(
    RelatedPostsDocument,
    baseOptions
  );
}
export type RelatedPostsQueryHookResult = ReturnType<
  typeof useRelatedPostsQuery
>;
export type RelatedPostsLazyQueryHookResult = ReturnType<
  typeof useRelatedPostsLazyQuery
>;
export type RelatedPostsQueryResult = Apollo.QueryResult<
  RelatedPostsQuery,
  RelatedPostsQueryVariables
>;
export const AllPostsDocument = gql`
  query allPosts($page: Int!, $pageSize: Int!) {
    allPosts(page: $page, pageSize: $pageSize) {
      pageInfo {
        ...simplePageInfo
      }
      nodes {
        ...postInfo
      }
    }
  }
  ${SimplePageInfoFragmentDoc}
  ${PostInfoFragmentDoc}
`;

/**
 * __useAllPostsQuery__
 *
 * To run a query within a React component, call `useAllPostsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllPostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllPostsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useAllPostsQuery(
  baseOptions: Apollo.QueryHookOptions<AllPostsQuery, AllPostsQueryVariables>
) {
  return Apollo.useQuery<AllPostsQuery, AllPostsQueryVariables>(
    AllPostsDocument,
    baseOptions
  );
}
export function useAllPostsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllPostsQuery,
    AllPostsQueryVariables
  >
) {
  return Apollo.useLazyQuery<AllPostsQuery, AllPostsQueryVariables>(
    AllPostsDocument,
    baseOptions
  );
}
export type AllPostsQueryHookResult = ReturnType<typeof useAllPostsQuery>;
export type AllPostsLazyQueryHookResult = ReturnType<
  typeof useAllPostsLazyQuery
>;
export type AllPostsQueryResult = Apollo.QueryResult<
  AllPostsQuery,
  AllPostsQueryVariables
>;
export const SearchPostsDocument = gql`
  query searchPosts($query: String!, $page: Int!, $pageSize: Int!) {
    searchPosts(query: $query, page: $page, pageSize: $pageSize) {
      pageInfo {
        ...simplePageInfo
      }
      nodes {
        ...postInfo
      }
    }
  }
  ${SimplePageInfoFragmentDoc}
  ${PostInfoFragmentDoc}
`;

/**
 * __useSearchPostsQuery__
 *
 * To run a query within a React component, call `useSearchPostsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchPostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchPostsQuery({
 *   variables: {
 *      query: // value for 'query'
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useSearchPostsQuery(
  baseOptions: Apollo.QueryHookOptions<
    SearchPostsQuery,
    SearchPostsQueryVariables
  >
) {
  return Apollo.useQuery<SearchPostsQuery, SearchPostsQueryVariables>(
    SearchPostsDocument,
    baseOptions
  );
}
export function useSearchPostsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchPostsQuery,
    SearchPostsQueryVariables
  >
) {
  return Apollo.useLazyQuery<SearchPostsQuery, SearchPostsQueryVariables>(
    SearchPostsDocument,
    baseOptions
  );
}
export type SearchPostsQueryHookResult = ReturnType<typeof useSearchPostsQuery>;
export type SearchPostsLazyQueryHookResult = ReturnType<
  typeof useSearchPostsLazyQuery
>;
export type SearchPostsQueryResult = Apollo.QueryResult<
  SearchPostsQuery,
  SearchPostsQueryVariables
>;
export const ShopPostsDocument = gql`
  query shopPosts($shopId: ID!, $page: Int!, $pageSize: Int!) {
    shopPosts(shopId: $shopId, page: $page, pageSize: $pageSize) {
      pageInfo {
        ...simplePageInfo
      }
      nodes {
        ...postInfo
      }
    }
  }
  ${SimplePageInfoFragmentDoc}
  ${PostInfoFragmentDoc}
`;

/**
 * __useShopPostsQuery__
 *
 * To run a query within a React component, call `useShopPostsQuery` and pass it any options that fit your needs.
 * When your component renders, `useShopPostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShopPostsQuery({
 *   variables: {
 *      shopId: // value for 'shopId'
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useShopPostsQuery(
  baseOptions: Apollo.QueryHookOptions<ShopPostsQuery, ShopPostsQueryVariables>
) {
  return Apollo.useQuery<ShopPostsQuery, ShopPostsQueryVariables>(
    ShopPostsDocument,
    baseOptions
  );
}
export function useShopPostsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ShopPostsQuery,
    ShopPostsQueryVariables
  >
) {
  return Apollo.useLazyQuery<ShopPostsQuery, ShopPostsQueryVariables>(
    ShopPostsDocument,
    baseOptions
  );
}
export type ShopPostsQueryHookResult = ReturnType<typeof useShopPostsQuery>;
export type ShopPostsLazyQueryHookResult = ReturnType<
  typeof useShopPostsLazyQuery
>;
export type ShopPostsQueryResult = Apollo.QueryResult<
  ShopPostsQuery,
  ShopPostsQueryVariables
>;
export const TagPostsDocument = gql`
  query tagPosts($tag: String!, $page: Int!, $pageSize: Int!) {
    tagPosts(tag: $tag, page: $page, pageSize: $pageSize) {
      pageInfo {
        ...simplePageInfo
      }
      nodes {
        ...postInfo
      }
    }
  }
  ${SimplePageInfoFragmentDoc}
  ${PostInfoFragmentDoc}
`;

/**
 * __useTagPostsQuery__
 *
 * To run a query within a React component, call `useTagPostsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTagPostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTagPostsQuery({
 *   variables: {
 *      tag: // value for 'tag'
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useTagPostsQuery(
  baseOptions: Apollo.QueryHookOptions<TagPostsQuery, TagPostsQueryVariables>
) {
  return Apollo.useQuery<TagPostsQuery, TagPostsQueryVariables>(
    TagPostsDocument,
    baseOptions
  );
}
export function useTagPostsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TagPostsQuery,
    TagPostsQueryVariables
  >
) {
  return Apollo.useLazyQuery<TagPostsQuery, TagPostsQueryVariables>(
    TagPostsDocument,
    baseOptions
  );
}
export type TagPostsQueryHookResult = ReturnType<typeof useTagPostsQuery>;
export type TagPostsLazyQueryHookResult = ReturnType<
  typeof useTagPostsLazyQuery
>;
export type TagPostsQueryResult = Apollo.QueryResult<
  TagPostsQuery,
  TagPostsQueryVariables
>;
