import React, { useEffect } from "react";
import { useRouter } from "next/router";
import {
  Avatar,
  Box,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Select,
  Text,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { FaRegUser } from "react-icons/fa";
import { routes } from "@src/constants/appRoutes";
import { useCurrentUserQuery } from "@src/generated/graphql";
import { locales } from "@src/constants/locales";

export default function Header() {
  return (
    <Flex
      as="header"
      flexShrink={0}
      bg="black"
      px={5}
      py={5}
      justifyContent="space-between"
      alignItems="center"
    >
      <Text color="white" fontWeight="bold">
        FASTLANE
      </Text>
      <Box display="flex" alignItems="center">
        {/* TODO: enable after translating main texts */}
        {/*<LanguageSelectButton />*/}
        <UserInfo />
      </Box>
    </Flex>
  );
}

const UserInfo = () => {
  const router = useRouter();
  const { data, loading, error } = useCurrentUserQuery();
  if (loading) {
    return <></>;
  }

  return (
    <Menu>
      <MenuButton as={Box} mx={3}>
        {data && data.currentUser.screenImgUrl ? (
          <Avatar
            size="sm"
            name={data.currentUser.displayName}
            src={data.currentUser.screenImgUrl}
          />
        ) : (
          <FaRegUser color="#fff" />
        )}
      </MenuButton>
      {!data || !data.currentUser ? (
        <MenuList>
          <MenuItem onClick={() => router.push(routes.login())}>
            ログイン
          </MenuItem>
          <MenuItem onClick={() => router.push(routes.signUp())}>
            会員登録
          </MenuItem>
        </MenuList>
      ) : (
        <MenuList>
          <MenuItem onClick={() => router.push(routes.userProfile())}>
            プロフィール
          </MenuItem>
        </MenuList>
      )}
    </Menu>
  );
};

const LanguageSelectButton = () => {
  const { i18n } = useTranslation();
  let initialLng = i18n.language;
  if (!Object.values(locales).includes(initialLng)) initialLng = locales.ja;
  const [lng, setLng] = React.useState(initialLng);

  useEffect(() => {
    i18n.changeLanguage(initialLng).then((r) => console.log(r));
  }, [i18n.language]);
  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng).then(() => {
      setLng(lng);
    });
  };

  return (
    <Select
      value={lng}
      onChange={(e) => changeLanguage(e.currentTarget.value)}
      maxWidth={120}
      background="transparent"
      borderColor="transparent"
      color="white"
    >
      <option value={locales.ja}>日本語</option>
      <option value={locales.en}>English</option>
      <option value={locales.zh}>中文</option>
    </Select>
  );
};
