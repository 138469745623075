export const routes = {
  home: () => "/",
  login: () => "/login",
  loginWithOriginalPath: (originalPath: string) =>
    `/login?originalUrl=${originalPath}`,
  signUp: () => "/sign_up",
  requireEmailVerification: () => "/user/require_email_verification",
  userProfile: () => "/user/profile",
  userFastLaneUsageHistories: () => "/user/fast_lane_usage_histories",
  userWaitingLineObservations: () => "/user/waiting_line_observations",
  userPaymentMethods: () => "/user/payment_methods",
  userFastLanePassports: () => "/user/fast_lane_passports",
  shopShow: (id: string) => `/shops/${id}`,
  fastLane: (shopId: string) => `/shops/${shopId}/fast_lane`,
  // fastLaneReady page takes query parama `?fluh=fastLaneUsageHistoryId`
  fastLaneReady: (shopId: string) => `/shops/${shopId}/fast_lane/ready`,
  fastLaneUsageHistory: (historyId: string) =>
    `/user/fast_lane_usage_histories/${historyId}`,
  business: () => "/business",
  inquiry: () => "/inquiry",
  privacyPolicy: () => "/privacy_policy",
  specifiedCommercialTransactionAct: () =>
    "/specified_commercial_transaction_act",
};
